import React from 'react';

import {
    Grid, Typography, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';

const QUESTIONS_ARRAY = [
    {
        question: <b>What is an Order Management solution?</b>,
        answer: <>An Order Management Solution is a system that efficiently handles the entire process of order processing, from order placement to fulfillment. VAO Labs offers a Generative AI SaaS solution for Order Management that delivers a seamless, EDI-like experience combined with Amazon-like status tracking. It streamlines order processing from various sources, including emails, faxes, and sales platforms.</>
    },
    {
        question: <b>How long does it take to implement Order Management software?</b>,
        answer: <>Speed is important, but effectiveness and accuracy are essential. With our Rapid Deployment Solutions, we can integrate and implement VAO with your systems in under a week.</>
    },
    {
        question: <b>A good amount of our customers have specific rules and exemptions, Can VAO handle customer-specific rules?</b>,
        answer: <>Yes, VAO's AI is designed to adapt to and manage custom business and customer-specific rules effectively. During the configuration process, specific rules and conditions unique to each customer are set up, ensuring that orders are processed accurately according to individual requirements.</>
    },
    {
        question: <b>What type of order can VAO OMS process?</b>,
        answer: <>VAO can process sales orders in various formats including PDF, Excel, HTML, CSV, and free-flow text.</>
    },
    {
        question: <b>Does VAO OMS support different order languages?</b>,
        answer: <>Yes, VAO supports multiple languages, such as English, Chinese, Spanish, Hindi, Arabic, German, Japanese, Portuguese, and more.</>
    },
    {
        question: <b>Will VAO OMS support emailed orders with no attachments?</b>,
        answer: <>Yes, VAO can handle orders received via email, even if they do not have attachments. For example, it can process orders in emails with only information in the EMAIL BODY " Please refill the previous order”  and no attachments.</>
    },
    {
        question: <b>How automated can be the order processing?</b>,
        answer: <>At VAO, we can fully automate your order management process to suit your needs. This includes options for touchless order approval, where the entire process is handled automatically by our AI. Alternatively, you can choose an AI co-pilot assistant, where orders are processed by our AI technology but require human approval for finalization. This flexibility ensures that your order processing can be as automated or supervised as you prefer.</>
    },
    {
        question: <b>How do we get started?</b>,
        answer: <>To get started with VAO, you can <a href="/contactUs" target="_blank" >Request a FREE Demo</a>. Our team will assist you in setting up the solution and customizing it to fit your specific order management needs.</>
    },
    {
        question: <b>Can VAO OMS integrate with ERP systems?</b>,
        answer: <>Yes, VAO can integrate seamlessly with major ERP systems such as SAP, Oracle, Microsoft Dynamics, and NetSuite.</>
    },
    {
        question: <b>Does VAO OMS have an API layer?</b>,
        answer: <>VAO operates an API layer offering a range of endpoints tailored to diverse enterprise needs. These APIs come with keys enabling seamless integration with enterprise resource planning (ERP) systems, such as SAP, facilitating instant order creation.</>
    },
    {
        question: <b>We have an RPA solution in place. Can this work together with VAO’s solution?</b>,
        answer: <>Yes, VAO can easily integrate with your RPA systems, complementing them and working smoothly together.</>
    },
    {
        question: <b>What differentiates VAO from other Order Management solutions?</b>,
        answer: <>VAO Labs' generative AI offers seamless, context-aware order processing without human intervention or recurring IT maintenance. Unlike traditional RPA, it handles diverse business rules flexibly. VAO provides end-to-end order management with features like format-independent processing, AI message reading, detailed reports, and advanced analytics, enhancing efficiency and transparency.</>
    },
    {
        question: <b>Are my documents and data secure at VAO?</b>,
        answer: <>Yes, your documents and data are secure at VAO. We utilize HTTPS encryption for data transmission to ensure privacy and integrity. Additionally, we employ token-based authentication to prevent unauthorized access to our APIs, providing a robust security framework for your information.</>
    },
]

const FaqView = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    return (
        <Grid container style={{ minHeight: '86vh' }}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" padding={isSmallScreen ? 3 : 5} paddingTop={10} spacing={5}>
                    <Grid item xs={12}>
                        <Grid container paddingLeft={isSmallScreen ? 0 : 15}>
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontFamily: 'Montserrat, sans-serif',
                                    }}
                                    variant="h2"
                                    textAlign={isSmallScreen ? "left" : "center"}
                                    textJustify="inter-word"
                                    color="#173d44"
                                >
                                    <b>FAQ</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {QUESTIONS_ARRAY.map(((element) => {
                            return (
                                <Accordion elevation={1}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "#173d44", fontSize: "45px" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            style={{
                                                fontFamily: 'Montserrat, sans-serif',
                                                textWrap: "wrap",
                                                textAlign: 'left'
                                            }}
                                        >
                                            {element["question"]}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            align="justify"
                                            style={{
                                                fontFamily: 'Montserrat, sans-serif',
                                                textWrap: "wrap",
                                            }}
                                        >
                                            {element["answer"]}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FaqView;