
import 'react-datasheet-grid/dist/style.css'

import _ from "lodash";

import React, { useEffect, useState } from 'react'

import { Grid } from "@mui/material";
import { DataSheetGrid, keyColumn } from 'react-datasheet-grid'

import "../../DetailsPage/JSONViewer/ExcelCss.css"

const TextComponent = React.memo(
  ({ rowData }) => {
    return (
      <textarea
        disabled
        style={{
          width: "100%",
          fontFamily: "inherit",
          border: "none",
          resize: "none",
        }}
      >
        {rowData}
      </textarea>
    )
  }
)

const textColumn = {
  component: TextComponent,
  deleteValue: () => '',
  copyValue: ({ rowData }) => rowData,
  pasteValue: () => '',
}

const DatasheetView = ({ POJSON, affectedRows }) => {
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);

  const getColumnsForData = (outputs) => {
    const uniqueKeys = new Set();
    outputs.forEach(obj => {
      const keys = Object.keys(obj);
      keys.forEach(key => {
        if (!uniqueKeys.has(key)) {
          uniqueKeys.add(key);
        }
      });
      const parsedArray = Array.from(uniqueKeys);
      const parsedDynamicColumns = parsedArray.map((columnKey) => {
        return {
          ...keyColumn(columnKey, textColumn), title: columnKey, grow: 2
        }
      })
      setColumns(parsedDynamicColumns);
    });
  }

  useEffect(() => {
    if (!_.isNil(POJSON) && !_.isEmpty(POJSON)) {
      getColumnsForData(POJSON);
      setData(POJSON);
    }
  }, [POJSON]);


  useEffect(() => {
    if (!_.isNil(affectedRows) && !_.isEmpty(affectedRows)) {
      setCurrentRow(new Set(affectedRows))
    } else {
      setCurrentRow(new Set([]));
    }
  }, [affectedRows])

  return (
    <>
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        textAlign="left"
        overflow="auto"
      >
        <Grid
          item
          xs={12}
        >
          {(_.isEmpty(data) && _.isNil(currentRow))
            ? <h2 style={{
              textAlign: "center"
            }}>
              No Information to show
            </h2>
            : <DataSheetGrid
              value={data}
              columns={columns}
              height={675}
              rowHeight={50}
              headerRowHeight={40}
              lockRows
              rowClassName={({ rowData }) => {
                const adjustedIndex = _.findIndex(data, (element) => element === rowData);
                if (currentRow.has(adjustedIndex)) {
                  return 'row-updated'
                }
              }}
            />
          }
        </Grid>
      </Grid>
    </>
  )
}

export default DatasheetView;
