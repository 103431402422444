import React, { useEffect, useState } from "react";
import {
    Drawer,
    IconButton,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import handleLogout from "../utils/handleLogout";
import { useNavigate } from "react-router-dom";

const SOLUTIONS_CASE = [
    { name: "Order To Cash", path: "/product/sales-order-automation" },
    { name: "Procure To Pay", path: "/product/procurement-software" },
    { name: "Invoice Management", path: "/product/vendor-invoice-management" },
]

const RESOURCES_CASE = [
    { name: "Blogs", path: "/blogs" },
    { name: "Events", path: "/events" },
]



const HamburgerMenuMobile = ({ token, textColor }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDrawer = (open) => () => {
        setIsOpen(open);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setIsOpen(false);
        // Close the menu after navigation
    };


    const handleLogoutCall = () => {
        handleLogout(() => navigate('/home'))
        setIsOpen(false);
    }

    return (
        <div>
            {/* Hamburger Icon */}
            <IconButton onClick={toggleDrawer(true)}>
                <MenuIcon sx={{ fontSize: "40px", color: textColor }} />
            </IconButton>

            {/* Drawer Menu */}
            <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
                <div
                    style={{
                        width: "300px",
                        padding: "16px",
                        backgroundColor: "#499CB5",
                        color: "white",
                        height: "100%",
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={toggleDrawer(false)}
                        style={{ float: "right", color: "white" }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* Menu Content */}
                    <Typography variant="h6" style={{ margin: "16px 0", fontWeight: "bold", fontFamily: 'Montserrat, sans-serif' }}>
                        VAO MENU
                    </Typography>
                    <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }} />
                    <List>
                        {token ? (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handleNavigation("/dashboard")}>
                                    <ListItemText primary={<Typography
                                        variant="subtitle1"
                                        style={{
                                            margin: "4px 0px 4px",
                                            fontWeight: "bold",
                                            fontFamily: 'Montserrat, sans-serif'
                                        }}
                                    >
                                        Dashboard
                                    </Typography>} />
                                </ListItemButton>
                            </ListItem>
                        ) : (
                            <>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => handleNavigation("/signUpPage")}>
                                        <ListItemText primary={<Typography
                                            variant="subtitle1"
                                            style={{
                                                margin: "4px 0px 4px",
                                                fontWeight: "bold",
                                                fontFamily: 'Montserrat, sans-serif'
                                            }}
                                        >
                                            Sign Up
                                        </Typography>} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.3)", margin: "8px 0" }} />
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => handleNavigation("/login")}>
                                        <ListItemText primary={<Typography
                                            variant="subtitle1"
                                            style={{
                                                margin: "4px 0px 4px",
                                                fontWeight: "bold",
                                                fontFamily: 'Montserrat, sans-serif'
                                            }}
                                        >
                                            Login
                                        </Typography>} />
                                    </ListItemButton>
                                </ListItem>
                            </>
                        )}
                        <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.3)", margin: "8px 0" }} />
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleNavigation("/company")}>
                                <ListItemText primary={<Typography
                                    variant="subtitle1"
                                    style={{
                                        margin: "4px 0px 4px",
                                        fontWeight: "bold",
                                        fontFamily: 'Montserrat, sans-serif'
                                    }}
                                >
                                    About Us
                                </Typography>} />
                            </ListItemButton>
                        </ListItem>
                        <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.3)", margin: "8px 0" }} />
                        <Typography variant="subtitle1" style={{ margin: "8px 0", fontWeight: "bold", fontFamily: 'Montserrat, sans-serif' }}>
                            Solutions
                        </Typography>
                        {SOLUTIONS_CASE.map((item) => (
                            <ListItem key={item.name} disablePadding>
                                <ListItemButton onClick={() => handleNavigation(item.path)}>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.3)", margin: "8px 0" }} />
                        <Typography variant="subtitle1" style={{ margin: "8px 0", fontWeight: "bold", fontFamily: 'Montserrat, sans-serif' }}>
                            Resources
                        </Typography>
                        {RESOURCES_CASE.map((item) => (
                            <ListItem key={item.name} disablePadding>
                                <ListItemButton onClick={() => handleNavigation(item.path)}>
                                    <ListItemText primary={item.name} style={{ fontFamily: 'Montserrat, sans-serif' }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.3)", margin: "8px 0" }} />
                        {token && (
                            <>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => handleLogoutCall()}>
                                        <ListItemText primary={<Typography
                                            variant="subtitle1"
                                            style={{
                                                margin: "4px 0px 4px",
                                                fontWeight: "bold",
                                                color: "#990f02",
                                                fontFamily: 'Montserrat, sans-serif'
                                            }}
                                        >
                                            LOGOUT
                                        </Typography>} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.3)", margin: "8px 0" }} />
                            </>
                        )}
                    </List>
                </div>
            </Drawer>
        </div>
    );
};

export default HamburgerMenuMobile;
